<template>
	<div class="index">
		<div id="load" class="sk-cube-grid" v-bind:class="{ 'hide': loading }">
			<div class="sk-cube sk-cube1"></div>
			<div class="sk-cube sk-cube2"></div>
			<div class="sk-cube sk-cube3"></div>
			<div class="sk-cube sk-cube4"></div>
			<div class="sk-cube sk-cube5"></div>
			<div class="sk-cube sk-cube6"></div>
			<div class="sk-cube sk-cube7"></div>
			<div class="sk-cube sk-cube8"></div>
			<div class="sk-cube sk-cube9"></div>
		</div>
		<!-- <modal></modal> -->
		<detalhe :broadcast="broadcasts_selecionado"></detalhe>
		<div class="content-broadcast">
			<div class="content-initial">
					<p class="title-brodcast">Broadcast</p>
					<router-link to="/broadcast/wizard">
						<button class="new-broadcast">Criar um Broadcast</button>
					</router-link>
			</div>
			<div class="content-pos espace-text">
				<p class="text-active">E-mails Mensais</p>
				<!-- <p class="text-detalhe">Visualização detalhada</p> -->
			</div>
			<div class="content-leads-actives">
					<p class="text-number-active fakeLeads divFakeLeads">{{ emails_mensal }}</p>
					<p class="text-number-total fakeLeads divFakeLeads">/ {{ leads_totais }}</p>
			</div>
			<div class="content-leads-actives2" v-bind:class="{ 'hide': daterangeopen }">
				<b-dropdown id="dropdown-left" text="Tipo do Grafico" class="lucas-drop">
					<b-dropdown-item @click="selectGraph('simples')" class="lucas-drop-op">Simples</b-dropdown-item>
					<b-dropdown-item @click="selectGraph('completo')" class="lucas-drop-op">Completo</b-dropdown-item>
				</b-dropdown>
			</div>
			<div class="content-initial">
				<div class="text-resume">
					<p class="text-resume-initial">Resumo total por</p>
					<div class="flex">
						<p class="text-resume-semana">{{ text_filter | moment("DD/MM/YYYY") }} </p>
						<p class="text-resume-semana2"> - {{ text_filter2 | moment("DD/MM/YYYY") }}</p>
					</div>
				</div>
				<div class="flex">
					<!-- <div class="flex text-topic"><div class="topic topic-green"></div>Abertos</div>
					<div class="flex text-topic"><div class="topic topic-blue"></div>Clicados</div> -->
				</div>
			</div>
			<div>
				<div id="fakeGrafico"></div>
				<!-- <line-chart></line-chart> -->
				<grafico></grafico>
			</div>
			<div class="flex">
				<p class="generate-font">Gerado em: {{ generate | moment("DD/MM/YYYY hh:mm:ss") }}</p>
				<p class="generate-font">Sera Atualizado em: {{ generate_next | moment("DD-MM-YYYY hh:mm:ss" ) }}</p>
			</div>
			<div class="recente-cards">
				<p class="title-brodcast">Recentes</p>
				<div class="content-cards">
					<div class="divide-cards">
						<div class="card-mail" v-for="(broadcast, x) in broadcasts" :key="x">
							<div class="espace-card-mail">
								<div class="flex space-between">
									<p class="title-broadcast">{{ broadcast.title }}</p>
									<p class="data-broadcast">{{ broadcast.send_at | moment("DD/MM/YYYY") }}</p>
								</div>
								<div class="line-board-broadcast"></div>
								<div class="flex flex-start">
									<div class="espace-especifics">
										<div class="flex">
											<div class="pointer-broadcast point-black"></div>
											<p class="text-especific">Processados</p>
										</div>
										<p class="value-especific" v-if="broadcast.fetched_count === null">0</p>
										<p class="value-especific" v-else>{{ broadcast.fetched_count }}</p>
									</div>
									<div class="espace-especifics">
										<div class="flex">
											<div class="pointer-broadcast point-greenn"></div>
											<p class="text-especific">Entregues</p>
										</div>
										<p class="value-especific">{{ broadcast.delivereds_count }} / {{ broadcast.percentageEntregues }}%</p>
									</div>
								</div>
								<div class="flex flex-start">
									<div class="espace-especifics">
										<div class="flex">
											<div class="pointer-broadcast"></div>
											<p class="text-especific">Abertos</p>
										</div>
										<p class="value-especific">{{ broadcast.opens_count }} / {{ broadcast.percentageOpen }}%</p>
									</div>
									<div class="espace-especifics">
										<div class="flex">
											<div class="pointer-broadcast point-blue2"></div>
											<p class="text-especific">Clicados</p>
										</div>
										<p class="value-especific">{{ broadcast.clicks_count }} / {{ broadcast.percentageClick }}%</p>
									</div>
								</div>
								<div class="flex flex-start">
									<div class="espace-especifics">
										<div class="flex">
											<div class="pointer-broadcast point-blue"></div>
											<p class="text-especific">Sairam</p>
										</div>
										<p class="value-especific">{{ broadcast.unsubscribeds_count }} / {{ broadcast.percentageUnsubscribeds }}%</p>
									</div>
								</div>
								<p class="email-card">{{ broadcast.from_address }}</p>
								<div class="flex space-between">
									<button class="btn-card" v-on:click="insereModal(broadcast)" @click="$bvModal.show('bv-modal-detalhe')">Detalhes</button>
									<!-- <div class="porcentagem"><p class="porcentagem-card">{{ broadcast.porcentagem }}%</p>
										<svg v-if="broadcast.positivo_geral" class="svg-porcentagem" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" fill="#00E4A0" fill-opacity="0.1"/><path d="M10 13V7M10 7L7 10M10 7L13 10" stroke="#00E4A0" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
										<svg v-else class="svg-porcentagem" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" fill="#FF0C37" fill-opacity="0.1"/><path d="M10 7V13M10 13L13 10M10 13L7 10" stroke="#FF0C37" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
									</div> -->
								</div>
							</div>
						</div>
						<div class="content-final">
							<router-link to="/broadcast/lista">
								<button class="btn-all-broadcast">Todos Broadcast</button>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import Modal from '../broadcast/ModalWizard';
import Detalhe from '../broadcast/ModalDetalhe';
import Grafico from '../broadcast/Grafico';
import Feed from '../broadcast/Feed';
import moment from '../../../node_modules/moment'
import EmailService from '@/services/resources/EmailService';
const serviceEmail =  EmailService.build();
const axios = require('axios');
import LeadListaService from '@/services/resources/LeadListaService';
const serviceLeadLista =  LeadListaService.build();
import UserResourcesService from '@/services/resources/UserResourcesService';
const serviceUserResourcesService =  UserResourcesService.build();
import UserLoggedService from '@/services/resources/UserLoggedService';
const serviceMe =  UserLoggedService.build();
import EmailTotalGraphService from '@/services/resources/EmailTotalGraphService';
const serviceEmailTotal =  EmailTotalGraphService.build();
import Cookies from "js-cookie";

export default {
	components: {
		// Modal,
		Detalhe,
		Feed,
		Grafico
	},
	name: 'index',
	data(){
		return{
			text_filter: moment().startOf('hour').subtract(169, 'hour'),
			text_filter2: moment().startOf('hour').subtract(1, 'hour'),
			updateFiltro:'aa',
			access_token:'',
			generate:'',
			generate_next: '',
			emails_mensal: '0000',
			leads_totais:'0000',
			startDate: moment().startOf('hour').subtract(730, 'hour').format('YYYY-MM-DD'),
			endDate: moment().startOf('hour').subtract(1, 'hour').format('YYYY-MM-DD'),
			broadcasts: [
				
			],
			broadcasts_selecionado: {},
			grafico_data:{},
			loading:false,
			type_graph: '',
			daterangeopen: false,
		}
	},
	 
	methods:{
		selectGraph(valor){
			if(valor === undefined){
				Cookies.set("typegraph", this.type_graph, { expires: 365 });
				this.$root.$emit('typegraph', this.type_graph);
			}else{
				this.type_graph = valor;
				Cookies.set("typegraph", this.type_graph, { expires: 365 });
				this.$root.$emit('typegraph', this.type_graph);
			}
		},
		getCookieGraph(){
			let cookie = Cookies.get("typegraph");
			console.log(cookie);
			if(cookie === 'simples'){
				this.type_graph = 'simples';
			}else if(cookie === 'completo'){
				this.type_graph = 'completo';
			}else if(cookie === undefined || cookie === undefined){
				this.type_graph = 'simples';
			}else{
				this.type_graph = 'simples';
			}
			this.selectGraph(cookie);
		},
		insereModal(broadcast){
			serviceEmail
            .read(broadcast.id)
            .then(resp => {
				console.log("get broadcast", resp);
				let enviado = resp.fetched_count;

				if(resp.selected_count !== 1 && resp.fetched_count === 1){
                    enviado = resp.selected_count;
                }

                if(enviado === 0 || enviado === null){
                    resp.percentageOpen = 0;
                    resp.percentageClick = 0;
                    resp.percentageSpam = 0;
                    resp.percentageDroppped = 0;
                    resp.percentageBounce = 0;
                    resp.percentageUnsubscribeds = 0;
                    resp.percentageDeferreds = 0;
                    resp.percentageDelivereds = 0;

                }else{
                    let aberto = resp.opens_count;
                    let clicks = resp.clicks_count;
                    let spam = resp.spams_count;
                    let dropped = resp.droppeds_count;
                    let bounce = resp.bounceds_count;
                    let unsubscribeds = resp.unsubscribeds_count;
                    let deferreds = resp.deferreds_count;
                    let delivereds = resp.delivereds_count;

                    let porcentagemAberto = (aberto/enviado) *100;
                    let porcentagemClicado = (clicks/enviado) *100;
                    let porcentagemSpam = (spam/enviado) *100;
                    let porcentagemDropped = (dropped/enviado) *100;
                    let porcentagemBounce = (bounce/enviado) *100;
                    let porcentagemUnsubscribeds = (unsubscribeds/enviado) *100;
                    let porcentagemDeferreds = (deferreds/enviado) *100;
                    let porcentagemDelivereds = (delivereds/enviado) *100;

                    resp.percentageOpen = parseInt(porcentagemAberto, 10);
                    resp.percentageClick = parseInt(porcentagemClicado, 10);
                    resp.percentageSpam = parseInt(porcentagemSpam, 10);
                    resp.percentageDroppped = parseInt(porcentagemDropped, 10);
                    resp.percentageBounce = parseInt(porcentagemBounce, 10);
                    resp.percentageUnsubscribeds = parseInt(porcentagemUnsubscribeds, 10);
                    resp.percentageDeferreds = parseInt(porcentagemDeferreds, 10);
                    resp.percentageDelivereds = parseInt(porcentagemDelivereds, 10);
                }
                this.broadcasts_selecionado = resp;
                this.$bvModal.show('bv-modal-detalhe');
            }).catch(err => {
                console.log(err);
            });
		},
		getMe(){
            serviceMe
            .search()
            .then(resp => {
                console.log("me", resp);
            }).catch(err => {
                console.log(err);
            });        
        },
		getList(){
			// var config = {
            //     headers: {'Authorization': 'MTQwOTkuMTk5MTAuZTEzMWM3Mzk0YTJlMDVhMjAzOGVkOGQwNmFiY2ZlYzYwN2E5YjdlMGJmNTJmMWEzMGMzZjUwMjdhNjYxNWM2OQ=='}
            // };
            // axios.get('https://api.innovaweb.com.br/email', config)

			serviceEmail
			.search(`status=ativos`)
			.then(resp => {
				console.log("busca recente", resp);

				let dadosvazios = resp.data;
                for (let i = 0; i < dadosvazios.length; i++) {

                    if(dadosvazios[i].title === ""){
                        dadosvazios[i].title = "Sem Título"
                    }

                    if(dadosvazios[i].from_address === ""){
                        dadosvazios[i].from_address = "Sem Remetente"
                    }
				}
				if(resp.data.length <= 4){
					this.broadcasts = resp.data.reverse();
				}else{
					var array = [];
					var data = resp.data;
					array.push(data[0])
					array.push(data[1])
					array.push(data[2])
					array.push(data[3])
					this.broadcasts = array;
				}

				let dados = this.broadcasts;
                for (let i = 0; i < dados.length; i++) {

                    //insere e calcula porcentagem
                    let enviado = dados[i].fetched_count;

                    if(enviado === 0 || enviado === null){
                        dados[i].percentageOpen = 0;
						dados[i].percentageClick = 0;
						dados[i].percentageUnsubscribeds = 0;
						dados[i].percentageEntregues = 0;
                    }else{
                        let aberto = dados[i].opens_count;
                        let clicks = dados[i].clicks_count;
                        let unsubscribeds = dados[i].unsubscribeds_count;
                        let entregue = dados[i].delivereds_count;
    
                        let porcentagemAberto = (aberto/enviado) *100;
						let porcentagemClicado = (clicks/enviado) *100;
						let porcentagemUnsubscribeds = (unsubscribeds/enviado) *100;
						let porcentagemEntregue = (entregue/enviado) *100;
    
                        dados[i].percentageOpen = parseInt(porcentagemAberto, 10);
						dados[i].percentageClick = parseInt(porcentagemClicado, 10);
						dados[i].percentageUnsubscribeds = parseInt(porcentagemUnsubscribeds, 10);
						dados[i].percentageEntregues = parseInt(porcentagemEntregue, 10);
					}
				}
								console.log("pega broadcasts", this.broadcasts);


				this.loadOff();
			}).catch(err => {
				console.log(err);
			});
		},
		getEmailMensal(){
			serviceEmailTotal
			.search('from_date='+this.startDate+'&to_date='+this.endDate)
			.then(resp => {
				console.log("busca 30 days", resp);
				let emails = resp.data.email_delivereds.count;
				if(emails !== null){
					this.emails_mensal = emails;
				}else{
					this.emails_mensal = 0;
				}
			}).catch(err => {
				console.log(err);
			});
		},
		removeLoading(){
			let div = document.querySelectorAll('.fakeLeads');
			for (let i = 0; i < div.length; i++) {
				div[i].classList.remove('divFakeLeads');
			}
		},
		getResources(){
			serviceUserResourcesService
			.search()
			.then(resp => {
				console.log("recursos", resp);
				this.leads_totais = (resp.leads*10) + (resp && resp.envios ? resp.envios : 0);
				setTimeout(() => {
					this.removeLoading();
				}, 200);
			}).catch(err => {
				console.log(err);
			});
		},
		loadOn(){
			this.loading = false;
		},
		loadOff(){
			this.loading = true;
		}
	},
	mounted(){
		this.getCookieGraph();
		this.getResources();
		this.$root.$on('updatefilter', data => {
			console.log(data);
			this.text_filter = data[0];
			this.text_filter2 = data[1];
			this.grafico_data = data;
		});
		this.$root.$on('updaterecente', data => {
			this.getList();
		});
		this.getList();
		this.getEmailMensal();
		this.$root.$on('getdataupdate', data => {
			this.generate = data.generated_at;
			this.generate_next = moment(data.generated_at).add(5, 'minutes').format('DD/MM/YYYY hh:mm:ss');
		});
		this.$root.$on('graphmounted', data => {
			document.getElementById('fakeGrafico').classList.add('hide');		
		});
		this.$root.$on('opendaterange', data => {
			this.daterangeopen = data;
		});
		// this.removeLoading();
	}
}
</script>
<style lang="scss">
.content-leads-actives2{
	button.btn.dropdown-toggle.btn-secondary {
		background: #fff;
		border: none;
		color: #000;
		height: 20px;
		position: relative;
		left: -22px;
		font-family: Montserrat;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
	}

	.dropdown-item {
		display: block;
		width: 100%;
		padding: 0.25rem 1.5rem;
		clear: both;
		font-weight: 400;
		color: #212529;
		text-align: inherit;
		white-space: nowrap;
		background-color: #0000;
		border: 0;
		font-family: Montserrat;
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
	}

	.dropdown-item:hover {
		background: #E4FAF5;
		color: #00E4A0;
	}

	ul.dropdown-menu.show {
		border: none;
	}

	.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
		color: #000;
		background-color: #fff;
		border-color: #fff;
	}

}
</style>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');



.sk-cube-grid {
  width: 80px;
  height: 80px;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  z-index: 999999999999999999999999999999999999999;  
  display: block
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}

#fakeGrafico {
    position: absolute;
    width: 62%;
    height: 300px;
    border-radius: 6px;
    animation: preload .8s forwards linear infinite alternate;
    background: #ededf0;
}

.divFakeLeads {
    color: #ededf000 !important;
    border-radius: 6px;
    animation: preload .8s forwards linear infinite alternate;
    background: #ededf0;
}

@keyframes preload {
  from {
    background: #EDEDF0;
  }

  to {
    background: rgb(192, 192, 192);
  }
}

.index {
    width: 65%;
    display: flex;
    margin-left: 98px;
	background: #FDFCFF;
}

.hide{
	display:none !important;
}

.point-black{
	background: #333 !important;
}

.point-greenn{
	background: #00E4A0 !important;
}

.point-blue{
	background: #2ED7EE !important;
}

.point-blue2{
	background: #3751ff !important;
}

.point-red{
	background: #FF0C37 !important;
}


.content-final{
	width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 45px;
    margin-bottom: 70px;
}
.svg-porcentagem{
	margin-left: 10px;
}

.btn-all-broadcast{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	letter-spacing: 0.2px;
	color: #333333;
	border: 1px solid #EDEDF0;
	box-sizing: border-box;
	border-radius: 3px;
	background-color: white;
	width: 273px;
	height: 53px;
}

.porcentagem{
	display: flex;
	margin-top: 16px;
}

.porcentagem-card{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #000000;
}

.btn-card{
	width: 136px;
	height: 44px;
	background: #00E4A0;
	border-radius: 3px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #FFFFFF;
	border:none;
}

.line-board-broadcast{
	height: 0.5px;
    background: #EDEDF0;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.flex-start{
	justify-content:space-between;
	width: 75%;
}

.space-between{
	justify-content: space-between;
}

.espace-especifics{
	margin-right: 15%;
}

.espace-card-mail{
	margin: 35px;
    width: 100%;
}

.value-especific{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: 0.2px;
	color: #000000;
    margin-left: 19px;
	margin-bottom: 30px;
}

.text-especific{
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: 0.2px;
	color: #818181;
	margin-bottom: 4px;
    margin-left: 12px;
}

.pointer-broadcast{
	width: 5px;
	height: 5px;
	background: #FFCB37;
	border-radius: 50%;
	margin-top: 7px;
}

.data-broadcast{
	margin-bottom: 0px;
	width: 80px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 150%;
	display: flex;
	align-items: center;
	color: #B5B9C5;
	margin-bottom: 42px;
}

.title-broadcast{
	margin-bottom: 0px;
    width: 126px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    color: #333;
    overflow: hidden;
    height: 71px;
}

.email-card{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #B5B9C5;
    margin-bottom: 25px;
}

.generate-font{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #B5B9C5;
	margin-top: 15px;
    margin-right: 25px;
    margin-left: 15px;
}

.card-mail{
    width: 49%;
    height: 490px;
    background: #fff;
    border: 1px solid #EDEDF0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 4px 4px rgba(237, 237, 240, 0.25);
    box-shadow: 0px 4px 4px rgba(237, 237, 240, 0.25);
    border-radius: 3px;
	display: flex;
	margin-bottom: 30px;
}

.divide-cards{
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    margin-right: 15%;
	margin-bottom: 30px;
	flex-wrap: wrap;
}

.recente-cards {
    margin-top: 60px;
}

.text-topic{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #000000;
	margin-left: 50px;
}

.flex{
	display: flex;
}

.text-resume-semana{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #333333;
}

.text-resume-semana2{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #333333;
	margin-left: 4px;
}

.text-resume-initial{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #B5B9C5;
	margin-right: 5px;
}

.text-resume{
	display: flex;
}

.topic{
    width: 5px;
    height: 5px;
	margin-top: 8px;
    margin-right: 10px;
    border-radius: 50%;
}

.topic-blue{
	background: #3751FF;
	box-shadow: 0px 0px 10px rgba(55, 81, 255, 0.25);
}

.topic-green{
	background: #00E4A0;
	box-shadow: 0px 0px 10px rgba(0, 228, 160, 0.25);
}

.content-leads-actives2 {
    width: 22%;
    display: flex;
    margin-top: -59px;
    float: right;
}

.content-leads-actives{
	width: 100%;
	display: flex;
	margin-top: -10px;
}

.text-number-active{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 150%;
	letter-spacing: 0.05em;
	color:  #00E4A0;
}

.text-number-total{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #B5B9C5;
    line-height: 57px;
}

.title-brodcast{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	color: #333333;
}

.content-broadcast {
    margin-left: 48px;
	margin-top: 35px;
	width: 100%;
}

.content-initial{
	display: flex;
    width: 99%;
    justify-content: space-between;
}

.text-active{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #B5B9C5;
}

.text-detalhe{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #333333;
}

.espace-text{
	margin-top: 50px;
}

.content-pos{
	display: flex;
    width: 99%;
    justify-content: space-between;
}

button.new-broadcast {
    width: 219px;
    height: 50px;
    border: none;
    background: #00E4A0;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.2px;
	color: #fff;
	margin-top: -13px;
}
</style>