<template>
	<div class="feed">
	    <div class="content-feed">
            <p class="font-feed">Estatísticas</p>
            <div class="flex justify-bt2 largura-title-feed">
                <div class="flex">
                    <!-- <multiselect v-model="selected" @change="selectxxx" :options="options" :searchable="false" :close-on-select="true" :show-labels="false"></multiselect> -->
                    <!-- <b-form-select v-model="selected" @change="selectxxx" :options="options"></b-form-select> -->
                     <div class="date-feed title-feed">
                         <svg class="svg-data-feed" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.22222 1V3.22222M3.77778 1V3.22222M1 5.44444H11M2.11111 2.11111H9.88889C10.5025 2.11111 11 2.60857 11 3.22222V11C11 11.6137 10.5025 12.1111 9.88889 12.1111H2.11111C1.49746 12.1111 1 11.6137 1 11V3.22222C1 2.60857 1.49746 2.11111 2.11111 2.11111Z" stroke="#00E4A0" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <date-range-picker
                            ref="picker"
                            :opens="opens"
                            :locale-data="{ 
                                firstDay: 1,
                                format: 'dd/mm/yyyy HH:MM:ss',
                                applyLabel: 'Aplicar',
                                cancelLabel: 'Cancelar',
                                monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                                daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                             }"
                            :minDate="minDate" :maxDate="maxDate"
                            :singleDatePicker="singleDatePicker"
                            :timePicker="timePicker"
                            :timePicker24Hour="timePicker24Hour"
                            :showWeekNumbers="showWeekNumbers"
                            :showDropdowns="showDropdowns"
                            v-model="dateRange"
                            :auto-apply="autoApply"
                            :ranges="show_ranges ? show_ranges : false"
                            @update="updateValues"
                            @toggle="checkOpen"
                            :always-show-calendars="false"
                            :alwaysShowCalendars="alwaysShowCalendars"
                            :append-to-body="appendToBody"
                            :closeOnEsc="closeOnEsc"
                        >
                            <template #input="picker" style="min-width: 350px;">
                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                            </template>
                        </date-range-picker>
                     </div>
                </div>
            </div>
            <div class="board-statistic">
                <div class="separator-feed">
                    <div class="content-statistic">
                        <svg width="60" height="23" viewBox="0 0 60 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.000244141 12.9056C0.000244141 12.5528 0.570821 12.2667 1.27466 12.2667C1.9785 12.2667 2.54908 12.5528 2.54908 12.9056V22.3612C2.54908 22.714 1.9785 23.0001 1.27466 23.0001C0.570821 23.0001 0.000244141 22.714 0.000244141 22.3612V12.9056Z" fill="#333333"/>
                            <path d="M6.37183 15.2055C6.37183 14.8527 6.9424 14.5667 7.64624 14.5667C8.35009 14.5667 8.92066 14.8527 8.92066 15.2055V22.3611C8.92066 22.7139 8.35009 23 7.64624 23C6.9424 23 6.37183 22.7139 6.37183 22.3611V15.2055Z" fill="#333333"/>
                            <path d="M50.9763 15.2055C50.9763 14.8527 51.5469 14.5667 52.2507 14.5667C52.9546 14.5667 53.5252 14.8527 53.5252 15.2055V22.3611C53.5252 22.7139 52.9546 23 52.2507 23C51.5469 23 50.9763 22.7139 50.9763 22.3611V15.2055Z" fill="#333333"/>
                            <path d="M57.3484 18.7833C57.3484 18.4304 57.919 18.1444 58.6228 18.1444C59.3266 18.1444 59.8972 18.4304 59.8972 18.7833V22.3611C59.8972 22.7139 59.3266 23 58.6228 23C57.919 23 57.3484 22.7139 57.3484 22.3611V18.7833Z" fill="#333333"/>
                            <path d="M25.488 15.2055C25.488 14.8527 26.0586 14.5667 26.7625 14.5667C27.4663 14.5667 28.0369 14.8527 28.0369 15.2055V22.3611C28.0369 22.7139 27.4663 23 26.7625 23C26.0586 23 25.488 22.7139 25.488 22.3611V15.2055Z" fill="#333333"/>
                            <path d="M12.7439 11.3723C12.7439 11.0194 13.3145 10.7334 14.0183 10.7334C14.7222 10.7334 15.2927 11.0194 15.2927 11.3723V22.3612C15.2927 22.714 14.7222 23.0001 14.0183 23.0001C13.3145 23.0001 12.7439 22.714 12.7439 22.3612V11.3723Z" fill="#333333"/>
                            <path d="M38.2322 11.3723C38.2322 11.0194 38.8028 10.7334 39.5066 10.7334C40.2104 10.7334 40.781 11.0194 40.781 11.3723V22.3612C40.781 22.714 40.2104 23.0001 39.5066 23.0001C38.8028 23.0001 38.2322 22.714 38.2322 22.3612V11.3723Z" fill="#333333"/>
                            <path d="M31.8601 12.9055C31.8601 12.5526 32.4307 12.2666 33.1345 12.2666C33.8384 12.2666 34.4089 12.5526 34.4089 12.9055V22.361C34.4089 22.7139 33.8384 22.9999 33.1345 22.9999C32.4307 22.9999 31.8601 22.7139 31.8601 22.361V12.9055Z" fill="#333333"/>
                            <path d="M19.116 7.02781C19.116 6.67496 19.6865 6.38892 20.3904 6.38892C21.0942 6.38892 21.6648 6.67496 21.6648 7.0278V22.3611C21.6648 22.714 21.0942 23 20.3904 23C19.6865 23 19.116 22.714 19.116 22.3611V7.02781Z" fill="#333333"/>
                            <path d="M44.6042 0.638889C44.6042 0.286041 45.1748 0 45.8787 0C46.5825 0 47.1531 0.28604 47.1531 0.638889V22.3611C47.1531 22.714 46.5825 23 45.8787 23C45.1748 23 44.6042 22.714 44.6042 22.3611V0.638889Z" fill="#333333"/>
                        </svg>
                        <p class="value-statistic fakeNumber divFake">{{ processados }}</p>
                        <p class="value-reference">Processados</p>
                    </div>
                    <div class="content-statistic">
                        <svg width="60" height="23" viewBox="0 0 60 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.000366211 12.9056C0.000366211 12.5527 0.570943 12.2667 1.27478 12.2667C1.97863 12.2667 2.5492 12.5527 2.5492 12.9056V22.3611C2.5492 22.714 1.97863 23 1.27478 23C0.570943 23 0.000366211 22.714 0.000366211 22.3611V12.9056Z" fill="#00E4A0"/>
                            <path d="M6.37195 15.2055C6.37195 14.8527 6.94252 14.5667 7.64637 14.5667C8.35021 14.5667 8.92079 14.8527 8.92079 15.2055V22.3611C8.92079 22.7139 8.35021 23 7.64637 23C6.94252 23 6.37195 22.7139 6.37195 22.3611V15.2055Z" fill="#00E4A0"/>
                            <path d="M50.9766 15.2055C50.9766 14.8527 51.5471 14.5667 52.251 14.5667C52.9548 14.5667 53.5254 14.8527 53.5254 15.2055V22.3611C53.5254 22.7139 52.9548 23 52.251 23C51.5471 23 50.9766 22.7139 50.9766 22.3611V15.2055Z" fill="#00E4A0"/>
                            <path d="M57.3486 18.7833C57.3486 18.4304 57.9192 18.1444 58.6231 18.1444C59.3269 18.1444 59.8975 18.4304 59.8975 18.7833V22.3611C59.8975 22.7139 59.3269 23 58.6231 23C57.9192 23 57.3486 22.7139 57.3486 22.3611V18.7833Z" fill="#00E4A0"/>
                            <path d="M25.488 15.2055C25.488 14.8527 26.0586 14.5667 26.7625 14.5667C27.4663 14.5667 28.0369 14.8527 28.0369 15.2055V22.3611C28.0369 22.7139 27.4663 23 26.7625 23C26.0586 23 25.488 22.7139 25.488 22.3611V15.2055Z" fill="#00E4A0"/>
                            <path d="M12.7439 11.3723C12.7439 11.0194 13.3145 10.7334 14.0183 10.7334C14.7222 10.7334 15.2927 11.0194 15.2927 11.3723V22.3612C15.2927 22.714 14.7222 23.0001 14.0183 23.0001C13.3145 23.0001 12.7439 22.714 12.7439 22.3612V11.3723Z" fill="#00E4A0"/>
                            <path d="M38.2324 11.3723C38.2324 11.0194 38.803 10.7334 39.5068 10.7334C40.2107 10.7334 40.7813 11.0194 40.7813 11.3723V22.3612C40.7813 22.714 40.2107 23.0001 39.5068 23.0001C38.803 23.0001 38.2324 22.714 38.2324 22.3612V11.3723Z" fill="#00E4A0"/>
                            <path d="M31.8602 12.9055C31.8602 12.5526 32.4308 12.2666 33.1346 12.2666C33.8385 12.2666 34.4091 12.5526 34.4091 12.9055V22.361C34.4091 22.7139 33.8385 22.9999 33.1346 22.9999C32.4308 22.9999 31.8602 22.7139 31.8602 22.361V12.9055Z" fill="#00E4A0"/>
                            <path d="M19.1161 7.02781C19.1161 6.67496 19.6867 6.38892 20.3905 6.38892C21.0943 6.38892 21.6649 6.67496 21.6649 7.0278V22.3611C21.6649 22.714 21.0943 23 20.3905 23C19.6867 23 19.1161 22.714 19.1161 22.3611V7.02781Z" fill="#00E4A0"/>
                            <path d="M44.6044 0.638889C44.6044 0.286041 45.1749 0 45.8788 0C46.5826 0 47.1532 0.28604 47.1532 0.638889V22.3611C47.1532 22.714 46.5826 23 45.8788 23C45.1749 23 44.6044 22.714 44.6044 22.3611V0.638889Z" fill="#00E4A0"/>
                        </svg>
                        <p class="value-statistic fakeNumber divFake">{{ entregues }} / {{ porcentagem_delivereds }}%</p>
                        <p class="value-reference">Entregues</p>
                    </div>
                </div>
                <div class="separator-feed">
                    <div class="content-statistic">
                        <svg width="61" height="23" viewBox="0 0 61 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.418579 12.9056C0.418579 12.5527 0.989156 12.2667 1.693 12.2667C2.39684 12.2667 2.96742 12.5527 2.96742 12.9056V22.3611C2.96742 22.714 2.39684 23 1.693 23C0.989156 23 0.418579 22.714 0.418579 22.3611V12.9056Z" fill="#3751FF"/>
                            <path d="M6.79004 15.2055C6.79004 14.8527 7.36062 14.5667 8.06446 14.5667C8.7683 14.5667 9.33888 14.8527 9.33888 15.2055V22.3611C9.33888 22.7139 8.7683 23 8.06446 23C7.36062 23 6.79004 22.7139 6.79004 22.3611V15.2055Z" fill="#3751FF"/>
                            <path d="M51.3948 15.2055C51.3948 14.8527 51.9654 14.5667 52.6692 14.5667C53.373 14.5667 53.9436 14.8527 53.9436 15.2055V22.3611C53.9436 22.7139 53.373 23 52.6692 23C51.9654 23 51.3948 22.7139 51.3948 22.3611V15.2055Z" fill="#3751FF"/>
                            <path d="M57.7667 18.7833C57.7667 18.4304 58.3373 18.1444 59.0411 18.1444C59.745 18.1444 60.3156 18.4304 60.3156 18.7833V22.3611C60.3156 22.7139 59.745 23 59.0411 23C58.3373 23 57.7667 22.7139 57.7667 22.3611V18.7833Z" fill="#3751FF"/>
                            <path d="M25.9062 15.2055C25.9062 14.8527 26.4768 14.5667 27.1807 14.5667C27.8845 14.5667 28.4551 14.8527 28.4551 15.2055V22.3611C28.4551 22.7139 27.8845 23 27.1807 23C26.4768 23 25.9062 22.7139 25.9062 22.3611V15.2055Z" fill="#3751FF"/>
                            <path d="M13.1621 11.3723C13.1621 11.0194 13.7327 10.7334 14.4365 10.7334C15.1404 10.7334 15.7109 11.0194 15.7109 11.3723V22.3612C15.7109 22.714 15.1404 23.0001 14.4365 23.0001C13.7327 23.0001 13.1621 22.714 13.1621 22.3612V11.3723Z" fill="#3751FF"/>
                            <path d="M38.6505 11.3723C38.6505 11.0194 39.2211 10.7334 39.9249 10.7334C40.6288 10.7334 41.1993 11.0194 41.1993 11.3723V22.3612C41.1993 22.714 40.6288 23.0001 39.9249 23.0001C39.2211 23.0001 38.6505 22.714 38.6505 22.3612V11.3723Z" fill="#3751FF"/>
                            <path d="M32.2784 12.9055C32.2784 12.5526 32.849 12.2666 33.5529 12.2666C34.2567 12.2666 34.8273 12.5526 34.8273 12.9055V22.361C34.8273 22.7139 34.2567 22.9999 33.5529 22.9999C32.849 22.9999 32.2784 22.7139 32.2784 22.361V12.9055Z" fill="#3751FF"/>
                            <path d="M19.5342 7.02781C19.5342 6.67496 20.1048 6.38892 20.8086 6.38892C21.5124 6.38892 22.083 6.67496 22.083 7.0278V22.3611C22.083 22.714 21.5124 23 20.8086 23C20.1048 23 19.5342 22.714 19.5342 22.3611V7.02781Z" fill="#3751FF"/>
                            <path d="M45.0226 0.638889C45.0226 0.286041 45.5932 0 46.297 0C47.0008 0 47.5714 0.28604 47.5714 0.638889V22.3611C47.5714 22.714 47.0008 23 46.297 23C45.5932 23 45.0226 22.714 45.0226 22.3611V0.638889Z" fill="#3751FF"/>
                        </svg>
                        <p class="value-statistic fakeNumber divFake">{{ clicados }} / {{ porcentagem_clicados }}%</p>
                        <p class="value-reference">Clicados</p>
                    </div>
                    <div class="content-statistic">
                        <svg width="60" height="23" viewBox="0 0 60 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.000366211 12.9056C0.000366211 12.5527 0.570943 12.2667 1.27478 12.2667C1.97863 12.2667 2.5492 12.5527 2.5492 12.9056V22.3611C2.5492 22.714 1.97863 23 1.27478 23C0.570943 23 0.000366211 22.714 0.000366211 22.3611V12.9056Z" fill="#FFCB37"/>
                            <path d="M6.37195 15.2055C6.37195 14.8527 6.94252 14.5667 7.64637 14.5667C8.35021 14.5667 8.92079 14.8527 8.92079 15.2055V22.3611C8.92079 22.7139 8.35021 23 7.64637 23C6.94252 23 6.37195 22.7139 6.37195 22.3611V15.2055Z" fill="#FFCB37"/>
                            <path d="M50.9766 15.2055C50.9766 14.8527 51.5471 14.5667 52.251 14.5667C52.9548 14.5667 53.5254 14.8527 53.5254 15.2055V22.3611C53.5254 22.7139 52.9548 23 52.251 23C51.5471 23 50.9766 22.7139 50.9766 22.3611V15.2055Z" fill="#FFCB37"/>
                            <path d="M57.3486 18.7833C57.3486 18.4304 57.9192 18.1444 58.6231 18.1444C59.3269 18.1444 59.8975 18.4304 59.8975 18.7833V22.3611C59.8975 22.7139 59.3269 23 58.6231 23C57.9192 23 57.3486 22.7139 57.3486 22.3611V18.7833Z" fill="#FFCB37"/>
                            <path d="M25.488 15.2055C25.488 14.8527 26.0586 14.5667 26.7625 14.5667C27.4663 14.5667 28.0369 14.8527 28.0369 15.2055V22.3611C28.0369 22.7139 27.4663 23 26.7625 23C26.0586 23 25.488 22.7139 25.488 22.3611V15.2055Z" fill="#FFCB37"/>
                            <path d="M12.7439 11.3723C12.7439 11.0194 13.3145 10.7334 14.0183 10.7334C14.7222 10.7334 15.2927 11.0194 15.2927 11.3723V22.3612C15.2927 22.714 14.7222 23.0001 14.0183 23.0001C13.3145 23.0001 12.7439 22.714 12.7439 22.3612V11.3723Z" fill="#FFCB37"/>
                            <path d="M38.2324 11.3723C38.2324 11.0194 38.803 10.7334 39.5068 10.7334C40.2107 10.7334 40.7813 11.0194 40.7813 11.3723V22.3612C40.7813 22.714 40.2107 23.0001 39.5068 23.0001C38.803 23.0001 38.2324 22.714 38.2324 22.3612V11.3723Z" fill="#FFCB37"/>
                            <path d="M31.8602 12.9055C31.8602 12.5526 32.4308 12.2666 33.1346 12.2666C33.8385 12.2666 34.4091 12.5526 34.4091 12.9055V22.361C34.4091 22.7139 33.8385 22.9999 33.1346 22.9999C32.4308 22.9999 31.8602 22.7139 31.8602 22.361V12.9055Z" fill="#FFCB37"/>
                            <path d="M19.1161 7.02781C19.1161 6.67496 19.6867 6.38892 20.3905 6.38892C21.0943 6.38892 21.6649 6.67496 21.6649 7.0278V22.3611C21.6649 22.714 21.0943 23 20.3905 23C19.6867 23 19.1161 22.714 19.1161 22.3611V7.02781Z" fill="#FFCB37"/>
                            <path d="M44.6044 0.638889C44.6044 0.286041 45.1749 0 45.8788 0C46.5826 0 47.1532 0.28604 47.1532 0.638889V22.3611C47.1532 22.714 46.5826 23 45.8788 23C45.1749 23 44.6044 22.714 44.6044 22.3611V0.638889Z" fill="#FFCB37"/>
                        </svg>
                        <p class="value-statistic fakeNumber divFake">{{ abertos }} / {{ porcentagem_abertos }}%</p>
                        <p class="value-reference">Abertos</p>
                    </div>
                </div>
                <div class="separator-feed">
                    <div class="content-statistic">
                        <svg width="61" height="23" viewBox="0 0 61 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.418579 12.9056C0.418579 12.5527 0.989156 12.2667 1.693 12.2667C2.39684 12.2667 2.96742 12.5527 2.96742 12.9056V22.3611C2.96742 22.714 2.39684 23 1.693 23C0.989156 23 0.418579 22.714 0.418579 22.3611V12.9056Z" fill="#2ED7EE"/>
                            <path d="M6.79004 15.2055C6.79004 14.8527 7.36062 14.5667 8.06446 14.5667C8.7683 14.5667 9.33888 14.8527 9.33888 15.2055V22.3611C9.33888 22.7139 8.7683 23 8.06446 23C7.36062 23 6.79004 22.7139 6.79004 22.3611V15.2055Z" fill="#2ED7EE"/>
                            <path d="M51.3948 15.2055C51.3948 14.8527 51.9654 14.5667 52.6692 14.5667C53.373 14.5667 53.9436 14.8527 53.9436 15.2055V22.3611C53.9436 22.7139 53.373 23 52.6692 23C51.9654 23 51.3948 22.7139 51.3948 22.3611V15.2055Z" fill="#2ED7EE"/>
                            <path d="M57.7667 18.7833C57.7667 18.4304 58.3373 18.1444 59.0411 18.1444C59.745 18.1444 60.3156 18.4304 60.3156 18.7833V22.3611C60.3156 22.7139 59.745 23 59.0411 23C58.3373 23 57.7667 22.7139 57.7667 22.3611V18.7833Z" fill="#2ED7EE"/>
                            <path d="M25.9062 15.2055C25.9062 14.8527 26.4768 14.5667 27.1807 14.5667C27.8845 14.5667 28.4551 14.8527 28.4551 15.2055V22.3611C28.4551 22.7139 27.8845 23 27.1807 23C26.4768 23 25.9062 22.7139 25.9062 22.3611V15.2055Z" fill="#2ED7EE"/>
                            <path d="M13.1621 11.3723C13.1621 11.0194 13.7327 10.7334 14.4365 10.7334C15.1404 10.7334 15.7109 11.0194 15.7109 11.3723V22.3612C15.7109 22.714 15.1404 23.0001 14.4365 23.0001C13.7327 23.0001 13.1621 22.714 13.1621 22.3612V11.3723Z" fill="#2ED7EE"/>
                            <path d="M38.6505 11.3723C38.6505 11.0194 39.2211 10.7334 39.9249 10.7334C40.6288 10.7334 41.1993 11.0194 41.1993 11.3723V22.3612C41.1993 22.714 40.6288 23.0001 39.9249 23.0001C39.2211 23.0001 38.6505 22.714 38.6505 22.3612V11.3723Z" fill="#2ED7EE"/>
                            <path d="M32.2784 12.9055C32.2784 12.5526 32.849 12.2666 33.5529 12.2666C34.2567 12.2666 34.8273 12.5526 34.8273 12.9055V22.361C34.8273 22.7139 34.2567 22.9999 33.5529 22.9999C32.849 22.9999 32.2784 22.7139 32.2784 22.361V12.9055Z" fill="#2ED7EE"/>
                            <path d="M19.5342 7.02781C19.5342 6.67496 20.1048 6.38892 20.8086 6.38892C21.5124 6.38892 22.083 6.67496 22.083 7.0278V22.3611C22.083 22.714 21.5124 23 20.8086 23C20.1048 23 19.5342 22.714 19.5342 22.3611V7.02781Z" fill="#2ED7EE"/>
                            <path d="M45.0226 0.638889C45.0226 0.286041 45.5932 0 46.297 0C47.0008 0 47.5714 0.28604 47.5714 0.638889V22.3611C47.5714 22.714 47.0008 23 46.297 23C45.5932 23 45.0226 22.714 45.0226 22.3611V0.638889Z" fill="#2ED7EE"/>
                        </svg>
                        <p class="value-statistic fakeNumber divFake">{{ sairam }} / {{ porcentagem_sairam }}%</p>
                        <p class="value-reference">Unsubscribeds</p>
                    </div>
                    <div class="content-statistic">
                        <svg width="60" height="23" viewBox="0 0 60 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.000244141 12.9056C0.000244141 12.5528 0.570821 12.2667 1.27466 12.2667C1.9785 12.2667 2.54908 12.5528 2.54908 12.9056V22.3612C2.54908 22.714 1.9785 23.0001 1.27466 23.0001C0.570821 23.0001 0.000244141 22.714 0.000244141 22.3612V12.9056Z" fill="#FF0C37"/>
                            <path d="M6.37183 15.2055C6.37183 14.8527 6.9424 14.5667 7.64624 14.5667C8.35009 14.5667 8.92066 14.8527 8.92066 15.2055V22.3611C8.92066 22.7139 8.35009 23 7.64624 23C6.9424 23 6.37183 22.7139 6.37183 22.3611V15.2055Z" fill="#FF0C37"/>
                            <path d="M50.9763 15.2055C50.9763 14.8527 51.5469 14.5667 52.2507 14.5667C52.9546 14.5667 53.5252 14.8527 53.5252 15.2055V22.3611C53.5252 22.7139 52.9546 23 52.2507 23C51.5469 23 50.9763 22.7139 50.9763 22.3611V15.2055Z" fill="#FF0C37"/>
                            <path d="M57.3484 18.7833C57.3484 18.4304 57.919 18.1444 58.6228 18.1444C59.3266 18.1444 59.8972 18.4304 59.8972 18.7833V22.3611C59.8972 22.7139 59.3266 23 58.6228 23C57.919 23 57.3484 22.7139 57.3484 22.3611V18.7833Z" fill="#FF0C37"/>
                            <path d="M25.488 15.2055C25.488 14.8527 26.0586 14.5667 26.7625 14.5667C27.4663 14.5667 28.0369 14.8527 28.0369 15.2055V22.3611C28.0369 22.7139 27.4663 23 26.7625 23C26.0586 23 25.488 22.7139 25.488 22.3611V15.2055Z" fill="#FF0C37"/>
                            <path d="M12.7439 11.3723C12.7439 11.0194 13.3145 10.7334 14.0183 10.7334C14.7222 10.7334 15.2927 11.0194 15.2927 11.3723V22.3612C15.2927 22.714 14.7222 23.0001 14.0183 23.0001C13.3145 23.0001 12.7439 22.714 12.7439 22.3612V11.3723Z" fill="#FF0C37"/>
                            <path d="M38.2322 11.3723C38.2322 11.0194 38.8028 10.7334 39.5066 10.7334C40.2104 10.7334 40.781 11.0194 40.781 11.3723V22.3612C40.781 22.714 40.2104 23.0001 39.5066 23.0001C38.8028 23.0001 38.2322 22.714 38.2322 22.3612V11.3723Z" fill="#FF0C37"/>
                            <path d="M31.8601 12.9055C31.8601 12.5526 32.4307 12.2666 33.1345 12.2666C33.8384 12.2666 34.4089 12.5526 34.4089 12.9055V22.361C34.4089 22.7139 33.8384 22.9999 33.1345 22.9999C32.4307 22.9999 31.8601 22.7139 31.8601 22.361V12.9055Z" fill="#FF0C37"/>
                            <path d="M19.116 7.02781C19.116 6.67496 19.6865 6.38892 20.3904 6.38892C21.0942 6.38892 21.6648 6.67496 21.6648 7.0278V22.3611C21.6648 22.714 21.0942 23 20.3904 23C19.6865 23 19.116 22.714 19.116 22.3611V7.02781Z" fill="#FF0C37"/>
                            <path d="M44.6042 0.638889C44.6042 0.286041 45.1748 0 45.8787 0C46.5825 0 47.1531 0.28604 47.1531 0.638889V22.3611C47.1531 22.714 46.5825 23 45.8787 23C45.1748 23 44.6042 22.714 44.6042 22.3611V0.638889Z" fill="#FF0C37"/>
                        </svg>
                        <p class="value-statistic fakeNumber divFake">{{ spam }} / {{ porcentagem_spam }}%</p>
                        <p class="value-reference">Spam</p>
                    </div>
                </div>
                <div class="separator-feed-min">
                    <div class="content-statistic">
                        <svg width="32" height="13" viewBox="0 0 32 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.000366211 6.89471C0.000366211 6.70621 0.305193 6.55339 0.681217 6.55339C1.05724 6.55339 1.36207 6.70621 1.36207 6.89471V11.9463C1.36207 12.1348 1.05724 12.2876 0.681217 12.2876C0.305193 12.2876 0.000366211 12.1348 0.000366211 11.9463V6.89471Z" fill="#818181"/>
                            <path d="M3.4043 8.12347C3.4043 7.93496 3.70912 7.78214 4.08515 7.78214C4.46117 7.78214 4.766 7.93496 4.766 8.12347V11.9463C4.766 12.1348 4.46117 12.2876 4.08515 12.2876C3.70912 12.2876 3.4043 12.1348 3.4043 11.9463V8.12347Z" fill="#818181"/>
                            <path d="M27.2341 8.12347C27.2341 7.93496 27.539 7.78214 27.915 7.78214C28.291 7.78214 28.5958 7.93496 28.5958 8.12347V11.9463C28.5958 12.1348 28.291 12.2876 27.915 12.2876C27.539 12.2876 27.2341 12.1348 27.2341 11.9463V8.12347Z" fill="#818181"/>
                            <path d="M30.6383 10.0349C30.6383 9.84635 30.9431 9.69354 31.3192 9.69354C31.6952 9.69354 32 9.84635 32 10.0349V11.9463C32 12.1348 31.6952 12.2876 31.3192 12.2876C30.9431 12.2876 30.6383 12.1348 30.6383 11.9463V10.0349Z" fill="#818181"/>
                            <path d="M13.6169 8.12347C13.6169 7.93496 13.9218 7.78214 14.2978 7.78214C14.6738 7.78214 14.9786 7.93496 14.9786 8.12347V11.9463C14.9786 12.1348 14.6738 12.2876 14.2978 12.2876C13.9218 12.2876 13.6169 12.1348 13.6169 11.9463V8.12347Z" fill="#818181"/>
                            <path d="M6.80847 6.07558C6.80847 5.88707 7.1133 5.73425 7.48932 5.73425C7.86535 5.73425 8.17017 5.88707 8.17017 6.07558V11.9463C8.17017 12.1348 7.86535 12.2876 7.48932 12.2876C7.1133 12.2876 6.80847 12.1348 6.80847 11.9463V6.07558Z" fill="#818181"/>
                            <path d="M20.4257 6.07558C20.4257 5.88707 20.7305 5.73425 21.1065 5.73425C21.4825 5.73425 21.7874 5.88707 21.7874 6.07558V11.9463C21.7874 12.1348 21.4825 12.2876 21.1065 12.2876C20.7305 12.2876 20.4257 12.1348 20.4257 11.9463V6.07558Z" fill="#818181"/>
                            <path d="M17.0214 6.89468C17.0214 6.70617 17.3262 6.55336 17.7022 6.55336C18.0782 6.55336 18.3831 6.70617 18.3831 6.89468V11.9463C18.3831 12.1348 18.0782 12.2876 17.7022 12.2876C17.3262 12.2876 17.0214 12.1348 17.0214 11.9463V6.89468Z" fill="#818181"/>
                            <path d="M10.2128 3.75456C10.2128 3.56605 10.5176 3.41324 10.8936 3.41324C11.2696 3.41324 11.5745 3.56605 11.5745 3.75456V11.9463C11.5745 12.1348 11.2696 12.2876 10.8936 12.2876C10.5176 12.2876 10.2128 12.1348 10.2128 11.9463V3.75456Z" fill="#818181"/>
                            <path d="M23.8298 0.341323C23.8298 0.152815 24.1347 0 24.5107 0C24.8867 0 25.1915 0.152815 25.1915 0.341322V11.9463C25.1915 12.1348 24.8867 12.2876 24.5107 12.2876C24.1347 12.2876 23.8298 12.1348 23.8298 11.9463V0.341323Z" fill="#818181"/>
                        </svg>
                        <p class="value-statistic-min fakeNumber divFake">{{ dropped }} / {{ porcentagem_dropped }}%</p>
                        <p class="value-reference-min">Dropped</p>
                    </div>
                    <div class="content-statistic">
                        <svg width="32" height="13" viewBox="0 0 32 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.000366211 6.89471C0.000366211 6.70621 0.305193 6.55339 0.681217 6.55339C1.05724 6.55339 1.36207 6.70621 1.36207 6.89471V11.9463C1.36207 12.1348 1.05724 12.2876 0.681217 12.2876C0.305193 12.2876 0.000366211 12.1348 0.000366211 11.9463V6.89471Z" fill="#5D21D2"/>
                            <path d="M3.4043 8.12347C3.4043 7.93496 3.70912 7.78214 4.08515 7.78214C4.46117 7.78214 4.766 7.93496 4.766 8.12347V11.9463C4.766 12.1348 4.46117 12.2876 4.08515 12.2876C3.70912 12.2876 3.4043 12.1348 3.4043 11.9463V8.12347Z" fill="#5D21D2"/>
                            <path d="M27.2341 8.12347C27.2341 7.93496 27.539 7.78214 27.915 7.78214C28.291 7.78214 28.5958 7.93496 28.5958 8.12347V11.9463C28.5958 12.1348 28.291 12.2876 27.915 12.2876C27.539 12.2876 27.2341 12.1348 27.2341 11.9463V8.12347Z" fill="#5D21D2"/>
                            <path d="M30.6383 10.0349C30.6383 9.84635 30.9431 9.69354 31.3192 9.69354C31.6952 9.69354 32 9.84635 32 10.0349V11.9463C32 12.1348 31.6952 12.2876 31.3192 12.2876C30.9431 12.2876 30.6383 12.1348 30.6383 11.9463V10.0349Z" fill="#5D21D2"/>
                            <path d="M13.6169 8.12347C13.6169 7.93496 13.9218 7.78214 14.2978 7.78214C14.6738 7.78214 14.9786 7.93496 14.9786 8.12347V11.9463C14.9786 12.1348 14.6738 12.2876 14.2978 12.2876C13.9218 12.2876 13.6169 12.1348 13.6169 11.9463V8.12347Z" fill="#5D21D2"/>
                            <path d="M6.80847 6.07558C6.80847 5.88707 7.1133 5.73425 7.48932 5.73425C7.86535 5.73425 8.17017 5.88707 8.17017 6.07558V11.9463C8.17017 12.1348 7.86535 12.2876 7.48932 12.2876C7.1133 12.2876 6.80847 12.1348 6.80847 11.9463V6.07558Z" fill="#5D21D2"/>
                            <path d="M20.4257 6.07558C20.4257 5.88707 20.7305 5.73425 21.1065 5.73425C21.4825 5.73425 21.7874 5.88707 21.7874 6.07558V11.9463C21.7874 12.1348 21.4825 12.2876 21.1065 12.2876C20.7305 12.2876 20.4257 12.1348 20.4257 11.9463V6.07558Z" fill="#5D21D2"/>
                            <path d="M17.0214 6.89468C17.0214 6.70617 17.3262 6.55336 17.7022 6.55336C18.0782 6.55336 18.3831 6.70617 18.3831 6.89468V11.9463C18.3831 12.1348 18.0782 12.2876 17.7022 12.2876C17.3262 12.2876 17.0214 12.1348 17.0214 11.9463V6.89468Z" fill="#5D21D2"/>
                            <path d="M10.2128 3.75456C10.2128 3.56605 10.5176 3.41324 10.8936 3.41324C11.2696 3.41324 11.5745 3.56605 11.5745 3.75456V11.9463C11.5745 12.1348 11.2696 12.2876 10.8936 12.2876C10.5176 12.2876 10.2128 12.1348 10.2128 11.9463V3.75456Z" fill="#5D21D2"/>
                            <path d="M23.8298 0.341323C23.8298 0.152815 24.1347 0 24.5107 0C24.8867 0 25.1915 0.152815 25.1915 0.341322V11.9463C25.1915 12.1348 24.8867 12.2876 24.5107 12.2876C24.1347 12.2876 23.8298 12.1348 23.8298 11.9463V0.341323Z" fill="#5D21D2"/>
                        </svg>
                        <p class="value-statistic-min fakeNumber divFake">{{ bounce }} / {{ porcentagem_bounce }}%</p>
                        <p class="value-reference-min">Bounce</p>
                    </div>
                    <div class="content-statistic">
                        <svg width="32" height="13" viewBox="0 0 32 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.000488281 6.89467C0.000488281 6.70616 0.305315 6.55334 0.681339 6.55334C1.05736 6.55334 1.36219 6.70616 1.36219 6.89467V11.9462C1.36219 12.1347 1.05736 12.2876 0.681339 12.2876C0.305315 12.2876 0.000488281 12.1347 0.000488281 11.9462V6.89467Z" fill="#FF8D00"/>
                            <path d="M3.40454 8.12343C3.40454 7.93492 3.70937 7.7821 4.08539 7.7821C4.46141 7.7821 4.76624 7.93492 4.76624 8.12343V11.9462C4.76624 12.1347 4.46141 12.2876 4.08539 12.2876C3.70937 12.2876 3.40454 12.1347 3.40454 11.9462V8.12343Z" fill="#FF8D00"/>
                            <path d="M27.2341 8.12343C27.2341 7.93492 27.539 7.7821 27.915 7.7821C28.291 7.7821 28.5958 7.93492 28.5958 8.12343V11.9462C28.5958 12.1347 28.291 12.2876 27.915 12.2876C27.539 12.2876 27.2341 12.1347 27.2341 11.9462V8.12343Z" fill="#FF8D00"/>
                            <path d="M30.6384 10.0348C30.6384 9.8463 30.9433 9.69348 31.3193 9.69348C31.6953 9.69348 32.0001 9.8463 32.0001 10.0348V11.9462C32.0001 12.1347 31.6953 12.2875 31.3193 12.2875C30.9433 12.2875 30.6384 12.1347 30.6384 11.9462V10.0348Z" fill="#FF8D00"/>
                            <path d="M13.6172 8.12343C13.6172 7.93492 13.922 7.7821 14.298 7.7821C14.6741 7.7821 14.9789 7.93492 14.9789 8.12343V11.9462C14.9789 12.1347 14.6741 12.2876 14.298 12.2876C13.922 12.2876 13.6172 12.1347 13.6172 11.9462V8.12343Z" fill="#FF8D00"/>
                            <path d="M6.80859 6.07558C6.80859 5.88707 7.11342 5.73425 7.48944 5.73425C7.86547 5.73425 8.17029 5.88707 8.17029 6.07558V11.9463C8.17029 12.1348 7.86547 12.2876 7.48944 12.2876C7.11342 12.2876 6.80859 12.1348 6.80859 11.9463V6.07558Z" fill="#FF8D00"/>
                            <path d="M20.4258 6.07558C20.4258 5.88707 20.7306 5.73425 21.1066 5.73425C21.4827 5.73425 21.7875 5.88707 21.7875 6.07558V11.9463C21.7875 12.1348 21.4827 12.2876 21.1066 12.2876C20.7306 12.2876 20.4258 12.1348 20.4258 11.9463V6.07558Z" fill="#FF8D00"/>
                            <path d="M17.0215 6.89467C17.0215 6.70616 17.3263 6.55334 17.7023 6.55334C18.0784 6.55334 18.3832 6.70616 18.3832 6.89467V11.9462C18.3832 12.1347 18.0784 12.2876 17.7023 12.2876C17.3263 12.2876 17.0215 12.1347 17.0215 11.9462V6.89467Z" fill="#FF8D00"/>
                            <path d="M10.2129 3.75453C10.2129 3.56602 10.5177 3.41321 10.8937 3.41321C11.2698 3.41321 11.5746 3.56602 11.5746 3.75453V11.9463C11.5746 12.1348 11.2698 12.2876 10.8937 12.2876C10.5177 12.2876 10.2129 12.1348 10.2129 11.9463V3.75453Z" fill="#FF8D00"/>
                            <path d="M23.8298 0.341323C23.8298 0.152815 24.1347 0 24.5107 0C24.8867 0 25.1915 0.152815 25.1915 0.341322V11.9463C25.1915 12.1348 24.8867 12.2876 24.5107 12.2876C24.1347 12.2876 23.8298 12.1348 23.8298 11.9463V0.341323Z" fill="#FF8D00"/>
                        </svg>
                        <p class="value-statistic-min fakeNumber divFake">{{ deferreds }} / {{ porcentagem_deferreds }}%</p>
                        <p class="value-reference-min">Deferreds</p>
                    </div>
                </div>
            </div>
            <div>
                <div class="generateFont">Gerado em: {{ data_gerado }}</div>
                <div class="generateFont">Sera Atualizado em: {{ data_gerado_next }}</div>
            </div>
            <div class="feed-mensages">
                <p class="font-feed">Feed</p>
                <div class="feed-list">
                    <b-list-group>
                        <b-list-group-item><div class="status-ball green-status"></div>Bem-vindo a G-Digital<svg class="svg-list" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" fill="#00E4A0" fill-opacity="0.1"/><path d="M10 13V7M10 7L7 10M10 7L13 10" stroke="#00E4A0" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg></b-list-group-item>
                        <b-list-group-item><div class="status-ball green-status"></div>Outro Broadcast<svg class="svg-list" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" fill="#00E4A0" fill-opacity="0.1"/><path d="M10 13V7M10 7L7 10M10 7L13 10" stroke="#00E4A0" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg></b-list-group-item>
                        <b-list-group-item><div class="status-ball red-status"></div>Broadcast legal<svg class="svg-list" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" transform="rotate(-180 10 10)" fill="#FF0C37" fill-opacity="0.1"/><path d="M10 7V13M10 13L13 10M10 13L7 10" stroke="#FF0C37" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg></b-list-group-item>
                        <b-list-group-item><div class="status-ball green-status"></div>Broadcast diferente<svg class="svg-list" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" fill="#00E4A0" fill-opacity="0.1"/><path d="M10 13V7M10 7L7 10M10 7L13 10" stroke="#00E4A0" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg></b-list-group-item>
                    </b-list-group>
                </div>
                <div class="btn-view-all">
                    <div class="btn-view-text">
                        Visualizar todas
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import Menu from '../broadcast/Menu';
import Multiselect from '../../../node_modules/vue-multiselect'
import DateRangePicker from '../../../node_modules/vue2-daterange-picker'
import '../../../node_modules/vue2-daterange-picker/dist/vue2-daterange-picker.css'
import dateUtil from '../../services/libs/native'
import moment from '../../../node_modules/moment'
import EmailTotalGraphService from '@/services/resources/EmailTotalGraphService';
const serviceEmailTotal =  EmailTotalGraphService.build();
const axios = require('axios');

export default {
    components: {
            Menu,
            Multiselect,
            DateRangePicker
		},
    name: 'feed',
     filters: {
      date (value) {
        if (!value)
          return ''
        let options = {year: 'numeric', month: 'numeric', day: 'numeric'};
        return Intl.DateTimeFormat('pt-BR', options).format(value)
      }
    },
    data() {
        return {
            opens: 'left',
            minDate: '2013-12-02 04:00:00',
            maxDate: moment().add(30, 'days').format(("YYYY-MM-DD HH:mm")),
            dateRange: {
                startDate: moment().startOf('hour').subtract(169, 'hour'),
                endDate: moment().startOf('hour').subtract(1, 'hour'),
            },
            show_ranges:this.dateAllPredefinido(),
            singleDatePicker: false,
            timePicker: false,
            maxSpan: 5,
            timePicker24Hour: true,
            showDropdowns: true,
            autoApply: false,
            showWeekNumbers: false,
            linkedCalendars: true,
            alwaysShowCalendars: true,
            appendToBody: false,
            closeOnEsc: true,
            data_gerado:'',
            data_gerado_next:'',
            processados: '00000',
            entregues: '00000',
            abertos: '00000',
            clicados: '00000',
            sairam: '000000',
            dropped: '0000',
            bounce: '0000',
            deferreds: '0000',
            spam: '0000',
            porcentagem_abertos: '0',
            porcentagem_clicados: '0',
            porcentagem_sairam: '0',
            porcentagem_dropped: '0',
            porcentagem_bounce: '0',
            porcentagem_deferreds: '0',
            porcentagem_spam: '0',
            porcentagem_delivereds:'0'


        }
    },
    methods:{
        updateValues (values) {
            console.log('event: update', {...values})
            // this.dateRange.startDate = dateUtil.format(values.startDate, 'yyyy-mm-dd');
            // this.dateRange.endDate = dateUtil.format(values.endDate, 'yyyy-mm-dd');
            let item = [moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')];
            this.$root.$emit('updatefilter', item);
            document.getElementById('fakeGrafico').classList.remove('hide');	
            let div = document.querySelectorAll('.fakeNumber');
                for (let i = 0; i < div.length; i++) {
                    div[i].classList.add('divFake');
                }
        },
        checkOpen (open) {
            console.log('event: open', open);
            this.$root.$emit('opendaterange', open);
        },
        dateAllPredefinido() {
            let today = new Date()
            today.setHours(0, 0, 0, 0)

            let yesterday = new Date()
            yesterday.setDate(today.getDate() - 1)
            yesterday.setHours(0, 0, 0, 0);

            let semana = new Date()
            semana.setDate(today.getDate() - 7)
            semana.setHours(0, 0, 0, 0);

            let ultimos = new Date()
            ultimos.setDate(today.getDate() - 30)
            ultimos.setHours(0, 0, 0, 0);


            let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
            let thisMonthStart2 = new Date(today.getFullYear(), today.getMonth(), 0);
            let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            let thisMonthEnd2 = new Date(today.getFullYear(), today.getMonth() - 1, 0);

            let todo = new Date(today.getFullYear());

            let datas = {
                'Hoje': [today, today],
                'Ontem': [yesterday, yesterday],
                'Últimos 7 dias': [semana, today],
                'Últimos 30 dias': [ultimos, today],
                'Mês Atual': [thisMonthStart, today],
                'Mês Passado': [thisMonthEnd2, thisMonthStart2],
                'Todo Período': [todo, today]
            }
            return datas;
        },
    },
    mounted(){
        this.$root.$on('getmingraph', data => {
            console.log(data);
            // var config = {
            //     headers: {'Authorization': 'Ni4xNTczOC41YjA5ZDA1ZjllYjk5YWE4OTQzOGExZDBjMmNkYjQxNmVhNjgyYjQ4MTNlNzM0ODM5NmRlNmQwYWIxNGI1Njhl'}
            // };
            // axios.get('https://api.innovaweb.com.br/email/total?from_date='+data[0]+'&to_date='+data[1], config)

            serviceEmailTotal
            .search('from_date='+data[0]+'&to_date='+data[1])
			.then(resp => {
                console.log("min graph", resp);
                console.log(resp.generated_at, moment(resp.generated_at).format('DD/MM/YYYY HH:mm:ss'));
                this.data_gerado = moment(resp.generated_at).format('DD/MM/YYYY HH:mm:ss');
                this.data_gerado_next = moment(resp.generated_at).add(5, 'minutes').format('DD/MM/YYYY HH:mm:ss');
                let processados = resp.data.email_fetched.count;
                let entregues = resp.data.email_delivereds.count;
                let abertos = resp.data.email_opens.count;
                let clicados = resp.data.email_clicks.count;
                let sairam = resp.data.email_unsubscribeds.count;
                let dropped = resp.data.email_droppeds.count;
                let bounce = resp.data.email_bounces.count;
                let spam = resp.data.email_spams.count;
                let deferreds = resp.data.email_deferreds.count;

                if(processados === null || processados === '0'){
                    processados = 0;
                }
                if(entregues === null || entregues === '0'){
                    entregues = 0;
                }
                if(abertos === null || abertos === '0'){
                    abertos = 0;
                }
                if(clicados === null || clicados === '0'){
                    clicados = 0;
                }
                if(sairam === null || sairam === '0'){
                    sairam = 0;
                }
                if(dropped === null || dropped === '0'){
                    dropped = 0;
                }
                if(bounce === null || bounce === '0'){
                    bounce = 0;
                }
                if(spam === null || spam === '0'){
                    spam = 0;
                }
                if(deferreds === null || deferreds === '0'){
                    deferreds = 0;
                }

                if(processados === 0 || processados === null || processados === '0'){
                    this.porcentagem_delivereds = 0;
                    this.porcentagem_abertos = 0;
                    this.porcentagem_clicados = 0;
                    this.porcentagem_sairam = 0;
                    this.porcentagem_dropped = 0;
                    this.porcentagem_bounce = 0;
                    this.porcentagem_spam = 0;
                    this.porcentagem_deferreds = 0;
                    
                }else{

                    let porcentagemDelivereds = (entregues/processados) *100;
                    let porcentagemAberto = (abertos/processados) *100;
                    let porcentagemClicado = (clicados/processados) *100;
                    let porcentagemUnsubscribeds = (sairam/processados) *100;
                    let porcentagemDropped = (dropped/processados) *100;
                    let porcentagemBounce = (bounce/processados) *100;
                    let porcentagemSpam = (spam/processados) *100;
                    let porcentagemDeferreds = (deferreds/processados) *100;

                    this.porcentagem_delivereds = parseInt(porcentagemDelivereds, 10);
                    this.porcentagem_abertos = parseInt(porcentagemAberto, 10);
                    this.porcentagem_clicados = parseInt(porcentagemClicado, 10);                    
                    this.porcentagem_sairam = parseInt(porcentagemUnsubscribeds, 10);
                    this.porcentagem_dropped = parseInt(porcentagemDropped, 10);
                    this.porcentagem_bounce = parseInt(porcentagemBounce, 10);
                    this.porcentagem_spam = parseInt(porcentagemSpam, 10);
                    this.porcentagem_deferreds = parseInt(porcentagemDeferreds, 10);
                    
                }

                this.processados = processados;
                this.entregues = entregues;
                this.abertos = abertos;
                this.clicados = clicados;
                this.sairam = sairam;
                this.dropped = dropped;
                this.bounce = bounce;
                this.spam = spam;
                this.deferreds = deferreds;

                let div = document.querySelectorAll('.fakeNumber');
                for (let i = 0; i < div.length; i++) {
                    div[i].classList.remove('divFake');
                }
			}).catch(err => {
				console.log(err);
			});
        });
    }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.generateFont{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #B5B9C5;
    margin-top: 5px;
}

.feed{
    width: 28%;
    height: 100%;
    background: #ffffff;
    position: fixed;
    right: 0px;
    // overflow: auto;
}

.feed::-webkit-scrollbar {
    background-color: #fff;
    width: 2px;
}

.feed::-webkit-scrollbar-track {
    background-color: #c1c1c1;
}

.feed::-webkit-scrollbar-thumb {
    background-color: #00000052;
    border-radius: 31px;
}

.feed::-webkit-scrollbar-button {
    display:none;
}

.divFake {
    color: #ededf000 !important;
    border-radius: 6px;
    animation: preload .8s forwards linear infinite alternate;
    background: #ededf0;
}

@keyframes preload {
  from {
    background: #EDEDF0;
  }

  to {
    background: rgb(192, 192, 192);
  }
}

.value-statistic-min{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 5px;
}

.value-reference-min{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    align-items: center;
    color: #B5B9C5;
}

.separator-feed-min {
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.separator-feed {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.list-group-item {
    border: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #B5B9C5;
    letter-spacing: 0.2px;
    display: flex;
    width: 92%;
    border-bottom: solid 0.3px #EDEDF0;
    margin-bottom: 10px;
    padding-bottom: 14px;
}

.largura-title-feed{
    width: 85%;
}

.form-control.reportrange-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
    height: 50px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 16px;
}

.feed .form-control.reportrange-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
    height: 50px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 16px;
}

.feed .form-control.reportrange-text:hover {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

.feed .form-control.reportrange-text:focus {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

option {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #333;
    height: 30px;
    margin-bottom: 10px;
}

.svg-data-feed{
    margin-right: 0px;
    margin-top: 9px;
    position: absolute;
    margin-left: 10px;
}

.custom-select {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    color: #333333;
    appearance: auto;
    border: 0.5px solid #EDEDF0;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(237, 237, 240, 0.1);
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 150%;
    display: flex;
    padding-left: 30px;
    align-items: center;
    background-image: none;
    padding-right: 13px;
}
.svg-list{
    margin-left: auto;
}

.btn-view-all{
    width: 273px;
    height: 44px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 3px;
}

.btn-view-text{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.2px;
    color: #333;
    margin-left: auto;
    margin-right: auto;
    display: table;
    line-height: 41px;
}

.status-ball{
    width: 5px;
    height: 5px;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 30px;
    margin-left: -19px;
    border-radius: 50%;
}

.flex{
    display: flex;
}

.justify-bt2{
    justify-content: space-between;
}

.red-status{
    background: #FF0C37;
}

.green-status{
    background: #00E4A0;
}

.content-feed{
    margin-top: 35px;
    margin-left: 60px;
}

.font-feed{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
}

.title-feed{
    margin-bottom: 25px;
}

.board-statistic{
    width: 91%;
    // height: 225px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 5px;
    padding-bottom: 10px;
    // display: flex;
    // justify-content: space-around;
}

.value-statistic{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000;
    margin-bottom: 11px;
    margin-top: 8px;
}

.value-reference{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: #B5B9C5;
}

.content-statistic{
    line-height: initial;
    margin-bottom: auto;
    margin-top: 10px;
}

.feed-mensages {
    margin-top: 30px;
    display: none;
}

</style>