<template>
    <b-modal id="bv-modal-detalhe" hide-footer>
        <div class="content">
            <div class="flex">
                <p class="fonte-detalhe">{{ broadcast.title }}</p>
            </div>
                <p class="fonte-data">{{ broadcast.send_at | moment("DD/MM/YYYY HH:mm") }}</p>
            <div class="flex">
                <div class="card-detalhes">
                    <p class="title-card">Métricas do Broadcast</p>
                    <div class="flex flex-metricas">
                        <div class="content-metrica">
                            <p class="title-metrica2">Processados</p>
                            <div class="flex">
                                <div class="pointer-process"></div>
                                <p class="number-metrica" v-if="broadcast.fetched_count === 1 && broadcast.selected_count !== 1">{{ broadcast.selected_count }}</p>
                                <p class="number-metrica" v-else-if="broadcast.fetched_count === null">0</p>
                                <p class="number-metrica" v-else>{{ broadcast.fetched_count }}</p>
                            </div>
                        </div>
                        <div class="content-metrica">
                            <p class="title-metrica2">Entregues</p>
                            <div class="flex">
                                <div class="pointer-enviado"></div>
                                <p class="number-metrica">{{ broadcast.delivereds_count }} / {{ broadcast.percentageDelivereds }}%</p>
                            </div>
                        </div>
                        <div class="content-metrica">
                            <p class="title-metrica2">Abertos</p>
                            <div class="flex">
                                <div class="pointer-aberto"></div>
                                <p class="number-metrica">{{ broadcast.opens_count }} / {{ broadcast.percentageOpen }}%</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-metricas topo">
                        <div class="content-metrica">
                            <p class="title-metrica2">Clicados</p>
                            <div class="flex">
                                <div class="pointer-clicados"></div>
                                <p class="number-metrica">{{ broadcast.clicks_count }} / {{ broadcast.percentageClick }}%</p>
                            </div>
                        </div>
                        <div class="content-metrica">
                            <p class="title-metrica2">Spam</p>
                            <div class="flex">
                                <div class="pointer-spam"></div>
                                <p class="number-metrica">{{ broadcast.spams_count }} / {{ broadcast.percentageSpam }}%</p>
                            </div>
                        </div>
                        <div class="content-metrica">
                            <p class="title-metrica2">Dropped</p>
                            <div class="flex">
                                <div class="pointer-dropped"></div>
                                <p class="number-metrica">{{ broadcast.droppeds_count }} / {{ broadcast.percentageDroppped }}%</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-metricas2 topo">
                        <div class="content-metrica">
                            <p class="title-metrica2">Bounce</p>
                            <div class="flex">
                                <div class="pointer-bounce"></div>
                                <p class="number-metrica">{{ broadcast.bounceds_count }} / {{ broadcast.percentageBounce }}%</p>
                            </div>
                        </div>
                        <div class="content-metrica">
                            <p class="title-metrica2">Unsubscribeds</p>
                            <div class="flex">
                                <div class="pointer-sairam"></div>
                                <p class="number-metrica">{{ broadcast.unsubscribeds_count }} / {{ broadcast.percentageUnsubscribeds }}%</p>
                            </div>
                        </div>
                        <div class="content-metrica">
                            <p class="title-metrica2">Deferreds</p>
                            <div class="flex">
                                <div class="pointer-cancel"></div>
                                <p class="number-metrica">{{ broadcast.deferreds_count }} / {{ broadcast.percentageDeferreds }}%</p>
                            </div>
                        </div>
                    </div>
                    <div class="espacamento-email">
                        <p class="email-detalhes"></p>
                    </div>
                    <div>
                        <button class="btn-view-leads" @click="visualizaEventos(broadcast.id)">Eventos do Broadcast</button>
                    </div>
                </div>
                <div>
                    <p class="preview-text">Preview do E-mail</p>
                    <iframe class="card-email" :srcdoc="broadcast.html"></iframe>
                </div>
            </div>
            <div class="espaceNewMail mt-3" v-if="broadcast.status === 5">
                <p class="preview-text">Ocorreu um erro na template do E-mail</p>
            </div>

        </div>
    </b-modal>
</template>
<script>
export default {
    props: {
        broadcast: Object
    },
    data(){
        return{
            selectedAction: null,
            optionsAction: [
                { value: null, text: 'Envio por Ação' },
                { value: 'email_click', text: 'Clicou' },
                { value: 'email_open', text: 'Abriu'},
                { value: 'email_not_click', text: 'Não Clicou'},
                { value: 'email_not_open', text: 'Não Abriu'},
            ],
        }
    },
    methods:{
        newBroadAction(broad){
            console.log('new create broad', broad);
            if(this.selectedAction !== null){
                this.$bvModal.hide('bv-modal-detalhe');
                this.$router.push('/broadcast/lista?broad_id='+broad.id+'&action_type='+this.selectedAction);
                this.$root.$emit('createnewbyaction');
            }
        },
        visualizaEventos(id){
             this.$router.push('/broadcast/lista/eventos?email='+id);
        }
    }
}
</script>
<style scoped>
.espaceNewMail {
    margin-top: -20px;
    display: flex;
    padding-left: 3px;
    padding-right: 3px;
}

.selectAction {
    height: 45px;
    border: 1px solid #EDEDF0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #333333;
}

.selectAction:hover {
    background: rgba(0, 228, 160, 0.05);
    border: 1px solid #00E4A0;
}

.selectAction:focus {
    border: 1px solid #00E4A0;
    background: rgba(0, 228, 160, 0.05);
}

.btnNewMail{
    height: 44px;
    background: #00E4A0;
    border-radius: 3px;
    border: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    margin-left: 15px;
    width: 316px;
}

.justify-title{
    justify-content: space-between;
}

.pointer-cancel{
    border-radius: 50%;
    background:#ff8d00;
    box-shadow: 0px 0px 10px rgba(201, 201, 201, 0.5);
    width: 8px;
    height: 8px;
 
}

.pointer-process{
    border-radius: 50%;
    background: #333;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    width: 8px;
    height: 8px;
}

.pointer-enviado{
    border-radius: 50%;
    background: #00E4A0;
    box-shadow: 0px 0px 10px rgba(0, 228, 160, 0.5);
    width: 8px;
    height: 8px;
}

.pointer-sairam{
    border-radius: 50%;
    background: #2ed7ee;
    box-shadow: 0px 0px 10px rgba(0, 228, 160, 0.5);
    width: 8px;
    height: 8px;
}


.pointer-aberto{
    border-radius: 50%;
    background: #FFCB37;
    box-shadow: 0px 0px 10px rgba(255, 203, 55, 0.5);    
    width: 8px;
    height: 8px;
}

.preview-text{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #333;
    margin-top: -40px;
}

.pointer-clicados{
    border-radius: 50%;
    background: #3751ff;
    box-shadow: 0px 0px 10px rgba(46, 215, 238, 0.5); 
    width: 8px;
    height: 8px;
}

.pointer-delivered{
    border-radius: 50%;
    background: #333;
    box-shadow: 0px 0px 10px rgba(181, 185, 197, 0.5);
    width: 8px;
    height: 8px;
 
}

.pointer-dropped{
    border-radius: 50%;
    background: #B5B9C5;
    box-shadow: 0px 0px 10px rgba(181, 185, 197, 0.5);
    width: 8px;
    height: 8px;
}

.pointer-bounce{
    border-radius: 50%;
    background: #5D21D2;
    box-shadow: 0px 0px 10px rgba(93, 33, 210, 0.5);
    width: 8px;
    height: 8px;
}

.pointer-spam{
    border-radius: 50%;
    background: #FF0C37;
    box-shadow: 0px 0px 10px rgba(255, 12, 55, 0.5);
    width: 8px;
    height: 8px;
}

.flex{
    display: flex;
}

.btn-view-leads{
    width: 229px;
    height: 44px;
    background: #00E4A0;
    border-radius: 3px;
    border: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
}

.email-detalhes{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #B5B9C5;
    margin-bottom: 43px;
}

.espacamento-email{
    margin-top: 20px;
}
.topo{
    margin-top: 10px;
}

.flex-metricas{
    justify-content: space-between;
    width: 250px;
}

.flex-metricas2{
    justify-content: space-around;
    width: 250px;
}

.number-metrica{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.2px;
    color: #333;
    margin-left: 5px;
    line-height: 8px;
}

.title-metrica2{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #333333;
}

.title-metrica{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #333333;
}

.card-detalhes{
    width: 458px;
    height: 391px;
    background: #FFFFFF;
    margin-top: 40px;
}

.svg-espace{
    margin-left: 10px;
}
.card-email{
    width: 458px;
    height: 391px;
    background: #FFFFFF;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 5px;
}

.title-card{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #000000;
    margin-bottom: 30px;
}

.content{
    margin-left: auto;
    margin-right: auto;
    /* width: 60%; */
    width: 800px;
}

.fonte-detalhe{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    height: 30px;
}

.fonte-data{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #818181;
}
</style>