import Rest from '@/services/Rest';

/**
 * @typedef {EmailGraphService}
 */
export default class EmailGraphService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'email/graph'


}